export const environment = {
    version : require('../../package.json').version,
    production: true,
    keycloakConfig : {
        clientId : 'facturacion-electronica-front',
        realm : 'FacturacionElectronicaRealm',
        url : 'https://keycloak.facturasend.com.py/auth/'
    },
    url: 'https://api.facturasend.com.py/',
    urlWSS: 'https://server.facturasend.com.py/',
    urlPWA: 'https://www.facturasend.com.py',
    publicAWSHost : 'https://s3.amazonaws.com/facturasend/app/',
    useCaptcha : true
};


const getPortFromURL = function(url) {
    const urlAnchor = document.createElement('a');
    urlAnchor.setAttribute('href', url);

    return urlAnchor.port;
}

if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(window.location.hostname)
    || window.location.hostname == 'localhost') {  
    console.log("A", getPortFromURL(environment.url), "B");
        environment.keycloakConfig.url = window.location.protocol + '//' + window.location.hostname + (getPortFromURL(environment.keycloakConfig.url) !== '' ? ':' + getPortFromURL(environment.keycloakConfig.url) : '') + '/auth/';
        environment.url = window.location.protocol + '//' + window.location.hostname + (getPortFromURL(environment.url) !== '' ? ':' + getPortFromURL(environment.url) : '') + '/api/';
        environment.urlWSS = window.location.protocol + '//' + window.location.hostname + ':' + (getPortFromURL(environment.urlWSS) !== '' ? ':' + getPortFromURL(environment.urlWSS) : '') + '/';
        environment.urlPWA = window.location.protocol + '//' + window.location.host;
        console.log("Se hace el cambio por el ip del front");
    }
    
    
    console.log(environment);