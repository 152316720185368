import { FuseNavigation } from '@fuse/types';

export const navigationAdmin: FuseNavigation[] = [    {
    id       : 'applications',
    title    : '- Inicio -',
    translate: 'NAV.APPLICATIONS',
    type     : 'group', 
    role: ['A', 'U', 'C'],
    children : [
        {
            id       : 'dashboards',
            title    : 'Dashboards',
            type     : 'item',
            url      : '/dashboard',
            role     : ['A', 'U', 'C']
        }],
    },
    //---
    {
        id       : 'documentos-electronicos',
        title    : '- Documentos Electrónicos -',
        translate: 'NAV.APPLICATIONS',
        type     : 'group', 
        role: ['A', 'U', 'C'],
        
        children : [
            {
                id       : 'factura-list',
                title    : 'Factura',
                type     : 'item',
                url      : '/de/1',
                role     : ['A', 'U', 'C'],
                /*badge    : {
                    title    : '1500',
                    //translate: 'NAV.MAIL.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }*/
            },
            {
                id       : 'autofactura-list',
                title    : 'Autofactura',
                type     : 'item',
                url      : '/de/4',
                role     : ['A', 'U', 'C']
            },
            {
                id       : 'nota-credito-list',
                title    : 'Nota de Crédito',
                type     : 'item',
                url      : '/de/5',
                role     : ['A', 'U', 'C']
                
            },
            {
                id       : 'nota-debito-list',
                title    : 'Nota de Débito',
                type     : 'item',
                url      : '/de/6',
                role     : ['A', 'U', 'C']
                
            },
            {
                id       : 'nota-remision-list',
                title    : 'Nota de Remisión',
                type     : 'item',
                url      : '/de/7',
                role     : ['A', 'U', 'C']
                
            },
            {
                id       : 'recibo-list',
                title    : 'Recibos',
                type     : 'item',
                url      : '/recibo',
                role     : ['A', 'U', 'C']
                
            },
            {
                id       : 'de-recibidos',
                title    : 'Documentos Recibidos',
                type     : 'item',
                url      : '/de-recibido',
                role     : ['A', 'U', 'C']
                
            }

        ]
    }, 
    //---
    {
        id       : 'consultas-set',
        title    : '- Consultas SET -',
        translate: 'NAV.APPLICATIONS',
        type     : 'group', 
        role: ['A', 'U', 'C'],
        children : [
            {
                id       : 'consulta-ruc',
                title    : 'Consulta de RUC',
                url      : '/consulta-ruc',
                type     : 'item',
                role     : ['A', 'U', 'C'],
            },{
                id       : 'consulta cdc',
                title    : 'Consulta por CDC',
                url      : '/consulta-cdc',
                type     : 'item',
                role     : ['A', 'U', 'C'],
            }]
    }, 
    //---
    {
        id       : 'procesos',
        title    : '- Procesos -',
        translate: 'NAV.APPLICATIONS',
        type     : 'group', 
        role: ['A', 'U', 'C'],
        children : [
            {
                id       : 'consulta-lotes',
                title    : 'Lotes enviados',
                url      : '/lotes',
                type     : 'item',
                role     : ['A', 'U', 'C'],
            },                 {
                id       : 'consulta-eventos',
                title    : 'Eventos',
                url      : '/eventos',
                type     : 'item',
                role     : ['A', 'U', 'C'],
            }, ]
    }, 
    //---
    {
        id       : 'exportar',
        title    : '- Exportar -',
        translate: 'NAV.APPLICATIONS',
        type     : 'group', 
        role: ['A', 'U', 'C'],
        children : [
            {
                id       : 'exportar-csv',
                title    : 'Exportar en CSV',
                url      : '/export-csv',
                type     : 'item',
                role     : ['A', 'U', 'C'],
            }]
    }, 
    //---
    {
        id       : 'configuracion',
        title    : '- Configuración -',
        translate: 'NAV.APPLICATIONS',
        type     : 'group', 
        role: ['A'],
        children : [
            {
                id       : 'parametros-list',
                title    : 'Contribuyente emisor',
                url      : '/contribuyente-emisor',
                type     : 'item',
                role     : ['A']
            }]
    }
];
