import { NgModule } from '@angular/core';
import { CallbackPipe } from './callback.pipe';
import { FirestoreDatePipe } from './firestore-date.pipe';
import { DateddMMyyyyPipe } from './ddMMyyyy.pipe';
import { DateddMMyyyyHHmmssPipe } from './ddMMyyyyHHmmss.pipe';

@NgModule({
  declarations: [FirestoreDatePipe, CallbackPipe, DateddMMyyyyPipe, DateddMMyyyyHHmmssPipe],
  exports: [
    FirestoreDatePipe,
    CallbackPipe,
    DateddMMyyyyPipe,
    DateddMMyyyyHHmmssPipe
  ]
})
export class PipesModule { }
